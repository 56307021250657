// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('channels')

const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(require.context('components', true))

// ref: https://www.vic-l.com/setup-bootstrap-in-rails-6-with-webpacker-for-development-and-production/
// ref: https://code-and-cookies.com/2020/01/new-rails-6-project/
require('bootstrap')

import '@fortawesome/fontawesome-free/js/all'

import '../styles/index.scss'

//
const d3 = require('d3')
const ApexCharts = require('apexcharts').default

//
global.d3 = d3
global.ApexCharts = ApexCharts
global.app = {}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
