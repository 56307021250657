import React, { // Babel expects React to be cased as a class
    useEffect as add_side_effect, // who's in charge of naming things for React?
    useRef as create_element_ref,
} from 'react'
import PROP_TYPES from 'prop-types'
import Apexchart from 'apexcharts'

import { format as d3_format } from 'd3'
const d3_format_float = d3_format(',.1f')
const d3_format_integer = d3_format(',.0f')

export default extend_component(Time_Series_Chart)

/////////// React component

function Time_Series_Chart(props) {
    const params = {
        ref: create_element_ref(),
        props: props,
    }
    const chart_renderer = get_chart_renderer(params)
    add_side_effect(chart_renderer)
    return render_component(params)
}

function render_component({ ref, props }) {
    // loader from https://loading.io/css/
    return <div ref={ ref }>
        <div className="time-series-chart">
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <p dangerouslySetInnerHTML={{ __html: props.snippet }}/>
    </div>
}

function extend_component(component) {
    component.propTypes = {
        title: PROP_TYPES.string,
        subtitle: PROP_TYPES.string,
        categories: PROP_TYPES.array,
        data: PROP_TYPES.array.isRequired,
    }
    return component
}

/////////// Apex chart

function get_chart_renderer(params) {
    let chart
    return render_chart

    ///////////

    function render_chart() {
        const chart_data = prepare_chart_data(params)
        const chart_options = get_chart_options(chart_data)
        const element = params.ref.current.querySelector(".time-series-chart")
        chart = new Apexchart(element, chart_options)
        chart.render()
        const loader = element.querySelector(".lds-default")
        element.removeChild(loader)
        return cleanup_chart
        // ^ React effect hooks should return a cleanup function
    }

    function cleanup_chart() {
        return chart.destroy()
    }
}

function prepare_chart_data(params) {
    const { data } = params.props

    const series = []
    const labels = []

    for (const d of data) {
        if (d.data && d.name) {
            series.push(d)
        } else {
            series.push({name: '', data: d})
        }
    }

    return {
        ...params.props,
        series: series,
        labels: labels,
    }
}

function get_chart_options(params) {
    const { series, options } = params
    const { title, subtitle } = params
    const CHART_WIDTH = 500
    const YAXIS_LABEL_WIDTH = options && options.yaxis && options.yaxis.labels
    && options.yaxis.labels.minWidth || 67
    const HEADING_OFFSET_X = YAXIS_LABEL_WIDTH - 5
    const XAXIS_MIN = options && options.xaxis && options.xaxis.min
    const XAXIS_MAX = options && options.xaxis && options.xaxis.max
    const XAXIS_FORMATTER = options && options.xaxis && options.xaxis.labels
        && options.xaxis.labels.formatter
    const YAXIS_MIN = options && options.yaxis && options.yaxis.min
    const YAXIS_MAX = options && options.yaxis && options.yaxis.max
    const YAXIS_FORMATTER = options && options.yaxis && options.yaxis.labels
        && options.yaxis.labels.formatter
        || ((y) => calculate_y_label(y, params))
    const YAXIS_TITLE = options && options.yaxis && options.yaxis.title
        || series.name

    return resolve_ops({
        title: {
            text: title,
            align: 'center',
            offsetX: HEADING_OFFSET_X,
            style: {
                fontSize: '18px',
            },
        },
        subtitle: {
            text: subtitle,
            align: 'center',
            offsetX: HEADING_OFFSET_X,
            style: {
                fontSize: '14px',
            },
        },
        series: series,
        chart: {
            width: CHART_WIDTH,
            type: 'line',
            toolbar: { show: false },
            animations: { enabled: false },
            selection: { enabled: false },
        },
        colors: [
            "rgb(4, 138, 168)",
            "rgb(145, 188, 203)",
            "rgb(193, 213, 221)",
            "rgb(239, 239, 239)",
        ],
        dataLabels: { enabled: false },
        xaxis: {
            type: 'numeric',
            min: XAXIS_MIN,
            max: XAXIS_MAX,
            labels: {
                show: true,
                formatter: XAXIS_FORMATTER,
                hideOverlappingLabels: true,
                style: {
                    fontSize: '14px',
                },
            },
            tickAmount: calculate_x_ticks(params),
            tooltip: {
                enabled: false,
            },
            crosshairs: {
                show: false,
            },
        },
        yaxis: {
            title: {
                text: YAXIS_TITLE,
            },
            min: YAXIS_MIN,
            max: YAXIS_MAX,
            labels: {
                formatter: YAXIS_FORMATTER,
                minWidth: YAXIS_LABEL_WIDTH,
                maxWidth: YAXIS_LABEL_WIDTH,
                style: {
                    fontSize: '14px',
                },
            },
            tickAmount: calculate_y_ticks(params),
        },
        legend: { show: false },
    })

    ///////////

    function resolve_ops(options) {
        const ops = {
            format_percentage: (value) => format_float(value, "percentage"),
        }
        return _resolve_ops(options)

        ///////////

        function _resolve_ops(_options) {
            if (Array.isArray(_options)) {
                return _options.map(_resolve_ops)
            } else if (_options && typeof _options === "object") {
                const resolved_options = { ..._options }
                const keys = Object.getOwnPropertyNames(resolved_options)
                for (const key of keys) {
                    let value = resolved_options[key]
                    if (!value) {
                        continue
                    } else if (typeof value === "object") {
                        resolved_options[key] = _resolve_ops(value)
                    } else if (typeof value === "string") {
                        const [ prefix, op_name ] = value.split(":", 2)
                        if (prefix === "op" && ops[op_name]) {
                            resolved_options[key] = ops[op_name]
                        }
                    }
                }
                return resolved_options
            }
            return _options
        }
    }
}

function calculate_y_label(y, params) {
    if ('percentage' !== params.value_type) {
        // Because SVI index is from 0 - 1
        if (calculate_y_max(params) <= 1) {
            return format_float(y, params.value_type)
        }
        return format_integer(y, params.value_type)
    }
    const max = calculate_y_max(params)
    return max < 2
        ? format_float(y, params.value_type)
        : format_integer(y, params.value_type)
}

function calculate_y_ticks(params) {
    if ('percentage' !== params.value_type) {
        // Because SVI index is from 0 - 1
        if (calculate_y_max(params) <= 1) {
            return 10
        }
        return undefined
    }
    const max = calculate_y_max(params)
    return max < 2
        ? 10
        : max <= 10
            ? max
            : max / 5
}

function calculate_y_min(params) {
    return undefined
}

function calculate_y_max(params) {
    return Math.max(...params.series.map( s => s.data.map( d => d.y ) ).flat() )
}

function calculate_x_ticks(params) {
    const max_series_len =  Math.max(...params.series.map( s => s.data.length ))
    return max_series_len / 2
}

/////////// Utilities

function get_float(value) {
    return value || Number(Number(value).toFixed(1))
}
function get_integer(value) {
    return value || Math.round(value)
}

function format_number(value, type, formatter) {
    if (is_empty(value)) {
        return '"No data"'
    }
    let formatted
    switch (type) {
      case 'percentage':
        formatted = format(value)
        formatted = `${ formatted }%`
        break
      case 'rate':
        formatted = d3_format_integer(value)
        break
      case 'currency':
        formatted = format(value)
        formatted = `$${ formatted }`
        break
      default:
        formatted = format(value)
        break
    }
    return formatted

    ///////////

    function format(value) {
        return value ? formatter(value) : "0"
    }
}

function format_float(value, type) {
    return format_number(value, type, d3_format_float)
}

function format_integer(value, type) {
    return format_number(value, type, d3_format_integer)
}

function is_empty(value) {
    return undefined === value || null === value
}
