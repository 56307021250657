import React from 'react'

export default Component

/////////// React component

function Component(props) {
    const params = {
        props: props,
    }
    return render_component(params)
}

function render_component({ props }) {
    const rendered_items = props.county_list.map(render_item)
    return <select defaultValue={ props.county.slug } onChange={ handle_change}>
        { rendered_items }
    </select>

    ///////////

    function render_item({ slug, county_name }) {
        return <option key={ slug } value={ slug }>
            { `${ county_name } County` }
        </option>
    }

    function handle_change(change_event) {
        const next_slug = change_event.target.value
        const current_slug_regex = new RegExp(`\\b${ props.county.slug }\\b`)
        window.location.pathname = window.location.pathname.replace(
            current_slug_regex,
            next_slug,
        )
    }
}
