import React from 'react'

const QUINTILE_COLOR = [
    'rgb(239, 239, 239)',
    'rgb(193, 213, 221)',
    'rgb(145, 188, 203)',
    'rgb(94, 163, 185)',
    'rgb(4, 138, 168)',
]

export default Component

/////////// React component

function Component(props) {
    const params = {
        props: props,
    }
    return render_component(params)
}

function render_component({ props }) {
    return <dl className="legend-quintile-colors">
        <dt style={{ backgroundColor: QUINTILE_COLOR[4] }}></dt>
            <dd><span className="heatmap-legend-text">
                81<sup>st</sup> - 100<sup>th</sup> (Top Quintile)
            </span></dd>
        <dt style={{ backgroundColor: QUINTILE_COLOR[3] }}></dt>
            <dd><span className="heatmap-legend-text">
                61<sup>st</sup> - 80<sup>th</sup>
            </span></dd>
        <dt style={{ backgroundColor: QUINTILE_COLOR[2] }}></dt>
            <dd><span className="heatmap-legend-text">
                41<sup>st</sup> - 60<sup>th</sup>
            </span></dd>
        <dt style={{ backgroundColor: QUINTILE_COLOR[1] }}></dt>
            <dd><span className="heatmap-legend-text">
                21<sup>st</sup> - 40<sup>th</sup>
            </span></dd>
        <dt style={{ backgroundColor: QUINTILE_COLOR[0] }}></dt>
            <dd><span className="heatmap-legend-text">
                1<sup>st</sup> - 20<sup>th</sup> (Bottom Quintile)
            </span></dd>
    </dl>
}
