import React, { // babel expects "React" to be cased as a class, not a namespace
    useEffect as add_side_effect, // who's in charge of naming things for react?
    useRef as create_element_ref,
} from 'react'
import PROP_TYPES from 'prop-types'
import Apexchart from 'apexcharts'

import { format as d3_format } from 'd3'
const d3_format_float = d3_format(',.1f')
const d3_format_integer = d3_format(',.0f')

export default extend_component(Component)

/////////// React component

function Component(props) {
    const params = {
        ref: create_element_ref(),
        props: props,
    }
    add_side_effect(get_chart_renderer(params))
    return render_component(params)
}

function render_component({ ref }) {
    // loader from https://loading.io/css/
    return <div ref={ ref }>
        <div className="plot-trend-chart">
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    </div>
}

function extend_component(component) {
    component.propTypes = {
        data: PROP_TYPES.array.isRequired,
        title: PROP_TYPES.string.isRequired,
        subtitle: PROP_TYPES.string,
        options: PROP_TYPES.object,
    }
    return component
}

/////////// Apex chart

function get_chart_renderer(params) {
    let chart
    return render_chart

    ///////////

    function render_chart() {
        const chart_data = prepare_chart_data(params)
        const chart_options = get_chart_options(chart_data)
        const element = params.ref.current.querySelector(".plot-trend-chart")
        chart = new Apexchart(element, chart_options)
        chart.render()
        const loader = element.querySelector(".lds-default")
        element.removeChild(loader)
        return cleanup_chart
        // ^ React effect hooks should return a cleanup function
    }

    function cleanup_chart() {
        return chart.destroy()
    }
}

function prepare_chart_data(params) {
    const { data } = params.props
    const series = []
    const labels = []

    for (const d of data) {
        if (d.data && d.name) {
            series.push({ ...d, type: 'scatter' })
        } else {
            series.push({type: 'scatter', name: '', data: d})
        }

        const correlation = calculate_correlation(d.data || d)
        if (correlation) {
            const max_x = calculate_x_max(d.data || d)
            const min_x = calculate_x_min(d.data || d)
            const end_y = correlation.slope * max_x + correlation.intercept
            const start_y = correlation.slope * min_x + correlation.intercept

            series.push(
                {
                    type: 'line',
                    name: 'correlation',
                    data: [
                        { x: min_x, y: start_y },
                        { x: max_x, y: end_y }
                    ]
                }
            )
        }
    }

    return {
        ...params.props,
        series: series,
        labels: labels,
    }
}

function get_chart_options(params) {
    const { series, options } = params
    const { title, subtitle, item } = params
    const CHART_WIDTH = 500
    const YAXIS_LABEL_WIDTH = 55
    const HEADING_OFFSET_X = YAXIS_LABEL_WIDTH - 5
    const ANNOTATION_LINE_OFFSET = (CHART_WIDTH - YAXIS_LABEL_WIDTH) / 2 - 17
    const YAXIS_TITLE = options && options.yaxis && options.yaxis.title
        || series.name
    const XAXIS_TITLE = options && options.xaxis && options.xaxis.title
        || ''
    const { xaxis, yaxis } = options
    const optionals = {}
    if (item) {
      optionals.annotations = {
          position: 'back',
          xaxis: [
              {
                  x: item.value,
                  strokeDashArray: 0,
                  borderColor: 'black', // stroke color
                  opacity: 1,
                  label: {
                      text: item.label,
                      orientation: 'horizontal',
                      borderWidth: 0,
                      offsetY: -6,
                      borderRadius: 2,
                      style: {
                          color: 'white',
                          background: 'black',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          padding: {
                              left: 6,
                              right: 6,
                              top: 3,
                              bottom: 4,
                          },
                      },
                  },
              },
          ],
      }
    }
    return {
        title: {
            text: title,
            align: 'center',
            offsetX: HEADING_OFFSET_X,
            style: {
                fontSize: '18px',
            },
        },
        subtitle: {
            text: subtitle,
            align: 'center',
            offsetX: HEADING_OFFSET_X,
            style: {
                fontSize: '14px',
            },
        },
        series: series,
        chart: {
            width: CHART_WIDTH,
            type: 'line',
            toolbar: { show: false },
            animations: { enabled: false },
            selection: { enabled: false },
            zoom: { enabled: false },
        },
        colors: [
            "rgb(4, 138, 168)",
            "rgb(145, 188, 203)",
            "rgb(193, 213, 221)",
            "rgb(239, 239, 239)",
        ],
        dataLabels: { enabled: false },
        markers: {
            size: [6, 0]
        },
        stroke: {
            width: [0, 4]
        },
        xaxis: {
            ...xaxis,
            tickAmount: 6,
            title: {
                text: XAXIS_TITLE
            },
            labels: {
                formatter: (x) => `${ x.toFixed() }%`, // for now...
            },
            crosshairs: {
                show: false,
            },
            tooltip: {
                enabled: false,
            }
        },
        yaxis: {
            ...yaxis,
            title: {
                text: YAXIS_TITLE
            },
            labels: {
                formatter: (y) => calculate_axis_label(y, params),
                minWidth: YAXIS_LABEL_WIDTH,
                maxWidth: YAXIS_LABEL_WIDTH,
                style: {
                    fontSize: '14px',
                },
            },
        },
        tooltip: {
            x: {
                formatter: (x, options) => {
                    const series = options.w.config.series[options.seriesIndex]
                    const point = series.data[options.dataPointIndex]
                    return `${ point.name } County`
                },
            },
            y: {
                formatter: (y, options) => {
                    const series = options.w.config.series[options.seriesIndex]
                    const point = series.data[options.dataPointIndex]
                    const xaxis = options.w.config.xaxis
                    const yaxis = options.w.config.yaxis[0]
                    return `
                        ${ yaxis.name }: ${ y.toFixed() }<br>
                        ${ xaxis.name }: ${ point.x.toFixed() }%
                    `
                }
            },
            shared: false,
        },
        legend: { show: false },
        ...optionals,
    }
}

///////////

function calculate_axis_label(v, params) {
    if ('percentage' !== params.value_type) {
        // Because SVI index is from 0 - 1
        if (calculate_y_max(params) <= 1) {
            return format_float(v, params.value_type)
        }
        return format_integer(v, params.value_type)
    }
    const max = calculate_y_max(params)
    return max < 2
        ? format_float(v, params.value_type)
        : format_integer(v, params.value_type)
}

function calculate_y_ticks(params) {
    if ('percentage' !== params.value_type) {
        // Because SVI index is from 0 - 1
        if (calculate_y_max(params) <= 1) {
            return 10
        }
        return undefined
    }
    const max = calculate_y_max(params)
    return max < 2
        ? 10
        : max <= 10
            ? max
            : max / 5
}

/////////// Utilities

function calculate_y_max(params) {
    return Math.max(...params.series.map( s => s.data.map( d => d.y ) ).flat() )
}

function calculate_x_max(data) {
  const max = Math.max(...data.map((item) => item.x))
  return max
}

function calculate_x_min(data) {
    const min = Math.min(...data.map((item) => item.x))
    return min
}

function calculate_correlation(data) {
    if (data.length < 1) {
        return false
    }
    const lr = {}
    const LEN = data.length
    let sum_x = 0, sum_y = 0, sum_xy = 0, sum_xx = 0, sum_yy = 0

    for (let i = 0; i < LEN; i++) {
        if (data[i] && !isNaN(data[i].x) && !isNaN(data[i].y)) {
            const x = Number(data[i].x)
            const y = Number(data[i].y)
            sum_x += x
            sum_y += y
            sum_xy += ( x * y )
            sum_xx += ( x * x )
            sum_yy += ( y * y )
        }
    } 
    lr['slope'] = (LEN * sum_xy - sum_x * sum_y) / (LEN * sum_xx - sum_x * sum_x)
    lr['intercept'] = (sum_y - lr['slope'] * sum_x) / LEN
    lr['r2'] = Math.pow((LEN * sum_xy - sum_x * sum_y) / Math.sqrt((LEN * sum_xx - sum_x * sum_x) * (LEN * sum_yy - sum_y * sum_y)), 2)
    return lr
}

function get_float(value) {
    return value || Number(Number(value).toFixed(1))
}

function get_integer(value) {
    return value || Math.round(value)
}

function format_number(value, type, formatter) {
    if (is_empty(value)) {
        return '"No data"'
    }

    var formatted

    const format = function(val){
      return(0 === val ? '0' : formatter(val))
    }

    switch (type) {
      case 'percentage':
      case 'percent':
        formatted = format(value)
        formatted = `${ formatted }%`
        break
      case 'rate':
        formatted = d3_format_integer(value)
        break
      default:
        formatted = format(value)
        break
    }

    return formatted
}

function format_float(value, type) {
    return format_number(value, type, d3_format_float)
}

function format_integer(value, type) {
    return format_number(value, type, d3_format_integer)
}

function is_empty(value) {
    return undefined === value || null === value
}
