import React from 'react'

const NAKED_PAGE_KEY = 'covid-19'
const PAGE_LIST = [
    { key: 'covid-19', value: 'COVID-19 Surveillance Indicators' },
    { key: 'immunization', value: 'COVID-19 Vaccination' },
    { key: 'demographic', value: 'Demographic' },
    { key: 'socioeconomic', value: 'Social and Economic' },
    { key: 'health', value: 'Health Risks' },
    { key: 'mental-health', value: 'Mental Health' },
    { key: 'economic', value: 'Economic Activity' },
    { key: 'distancing', value: 'Challenges to Physical Distancing' },
]

export default Component

/////////// React component

function Component(props) {
    const params = {
        props: props,
    }
    return render_component(params)
}

function render_component({ props, link_list }) {
    const rendered_items = PAGE_LIST.map(render_item)
    return <ul className="county-page-switcher">
        { rendered_items }
    </ul>

    ///////////

    function render_item({ key, value }) {
        const html_class = key === props.page
            ? 'active'
            : !props.page && NAKED_PAGE_KEY === key
                ? 'active'
                : ''
        return <li key={ key }>
            <a
                href={ `/county/${ props.slug }/${ key }` }
                className={ html_class }
            >
                { value }
            </a>
        </li>
    }
}
