import React, { // babel expects "React" to be cased as a class, not a namespace
    useEffect as add_side_effect, // who's in charge of naming things for react?
    useRef as create_element_ref,
} from 'react'
import PROP_TYPES from 'prop-types'
import Apexchart from 'apexcharts'

import { format as d3_format } from 'd3'
const d3_format_float = d3_format(',.1f')
const d3_format_integer = d3_format(',.0f')

export default extend_component(Component)

/////////// React component

function Component(props) {
    const params = {
        ref: create_element_ref(),
        props: props,
    }
    add_side_effect(get_chart_renderer(params))
    return render_component(params)
}

function render_component({ ref }) {
    // loader from https://loading.io/css/
    return <div ref={ ref }>
        <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
}

function extend_component(component) {
    const item = PROP_TYPES.exact({
        label: PROP_TYPES.string.isRequired,
        value: PROP_TYPES.number, // can be null
    })
    component.propTypes = {
        title_suffix: PROP_TYPES.string.isRequired,
        subtitle: PROP_TYPES.string,
        item: item.isRequired,
        value_type: PROP_TYPES.string,
        list: PROP_TYPES.arrayOf(item).isRequired,
    }
    return component
}

/////////// Apex chart

function get_chart_renderer(params) {
    let chart
    return render_chart

    ///////////

    function render_chart() {
        const chart_data = prepare_chart_data(params)
        const chart_options = get_chart_options(chart_data)
        const element = params.ref.current
        chart = new Apexchart(element, chart_options)
        chart.render()
        const loader = element.querySelector(".lds-default")
        element.removeChild(loader)
        return cleanup_chart
        // ^ React effect hooks should return a cleanup function
    }

    function cleanup_chart() {
        return chart.destroy()
    }
}

function prepare_chart_data(params) {
    const { item, list } = params.props
    const item_i = find_item_index({ item: item, list: list })
    if (-1 === item_i) {
        throw new Error('item is not in list')
    }
    const series = []
    const labels = []
    const column_count = get_column_count(list)
    // ^ So when item_i is 0 or list.length-1, it's the middle column
    while (series.length < 5) { // 5 cause quintiles
        series.push({
            name: '', // no real good name
            data: Array(column_count).fill(null),
        })
        labels.push(Array(column_count).fill(null))
    }

    const n = list.length
    const initial_x = n - 1 - item_i // put item_i in the middle column
    const quintile_i_range = n / 5
    for (let i = 0; i < n; i++) {
        const { value, label } = list[i]
        const bin = Math.floor(i / quintile_i_range)
        const x = initial_x + i
        const y = get_float(value)
        series[bin].data[x] = y
        labels[bin][x] = label
    }
    return {
        ...params.props,
        value: get_float(item.value),
        series: series,
        labels: labels,
    }
}

function get_chart_options(params) {
    const { series, labels } = params
    const { title_suffix, subtitle, item, list, value_type } = params
    const CHART_WIDTH = 500
    const YAXIS_LABEL_WIDTH = 42
    const HEADING_OFFSET_X = YAXIS_LABEL_WIDTH - 5
    const ANNOTATION_LINE_OFFSET = (CHART_WIDTH - YAXIS_LABEL_WIDTH) / 2 - 17
    const XAXIS_TITLE = 'Counties in Colorado from Lowest to Highest'
    const title_value = is_empty(item.value)
        ? "No data on"
        : value_type === "count"
            ? format_integer(item.value, value_type)
            : format_float(item.value, value_type)
    return {
        title: {
            text: `${ title_value } ${ title_suffix }`,
            align: 'center',
            offsetX: HEADING_OFFSET_X,
            style: {
                fontSize: '18px',
            },
        },
        subtitle: {
            text: subtitle,
            align: 'center',
            offsetX: HEADING_OFFSET_X,
            style: {
                fontSize: '14px',
            },
        },
        series: series,
        chart: {
            width: CHART_WIDTH,
            type: 'bar',
            toolbar: { show: false },
            animations: { enabled: false },
            selection: { enabled: false },
        },
        plotOptions: {
            bar: {
                columnWidth: 555,
            },
        },
        colors: [
            "rgb(239, 239, 239)",
            "rgb(193, 213, 221)",
            "rgb(145, 188, 203)",
            "rgb(94, 163, 185)",
            "rgb(4, 138, 168)",
        ],
        dataLabels: { enabled: false },
        xaxis: {
            min: 0,
            max: get_column_count(list),
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: { show: false },
            tooltip: { enabled: false },
            title: {
                text: XAXIS_TITLE,
            },
        },
        yaxis: {
            labels: {
                formatter: (y) => calculate_y_label(y, params),
                minWidth: YAXIS_LABEL_WIDTH,
                maxWidth: YAXIS_LABEL_WIDTH,
                style: {
                    fontSize: '14px',
                },
            },
            tickAmount: calculate_y_ticks(params),
            min: calculate_y_min(params),
            max: calculate_y_max(params),
        },
        annotations: {
            position: 'front',
            xaxis: [
                {
                    x: ANNOTATION_LINE_OFFSET,
                    strokeDashArray: 0,
                    borderColor: 'black', // stroke color
                    opacity: 1,
                    label: {
                        text: item.label,
                        orientation: 'horizontal',
                        borderWidth: 0,
                        offsetY: -6,
                        borderRadius: 2,
                        style: {
                            color: 'white',
                            background: 'black',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: {
                                left: 6,
                                right: 6,
                                top: 3,
                                bottom: 4,
                            },
                        },
                    },
                },
            ],
        },
        legend: { show: false },
        tooltip: {
            style: {
                fontSize: '14px',
            },
            x: {
                formatter: (x, options) => {
                    const s = options.seriesIndex
                    const i = options.dataPointIndex
                    return labels[s][i]
                },
            },
            y: {
                formatter: (y) => format_float(y, value_type),
            },
        },
    }
}


function calculate_y_label(y, params) {
    if ('percentage' !== params.value_type) {
        // Because SVI index is from 0 - 1
        if (calculate_y_max(params) <= 1) {
            return format_float(y, params.value_type)
        }
        return format_integer(y, params.value_type)
    }
    const max = calculate_y_max(params)
    return max < 2
        ? format_float(y, params.value_type)
        : format_integer(y, params.value_type)
}
function calculate_y_ticks(params) {
    if ('percentage' !== params.value_type) {
        // Because SVI index is from 0 - 1
        if (calculate_y_max(params) <= 1) {
            return 10
        }
        return undefined
    }
    const max = calculate_y_max(params)
    return max < 2
        ? 10
        : max <= 10
            ? max
            : max / 5
}
function calculate_y_min(params) {
    return undefined
}
function calculate_y_max(params) {
    if ('percentage' !== params.value_type && 'index' !== params.value_type) {
        return undefined
    }
    const max = Math.max(...params.list.map((item) => item.value))
    return max <= 10
        ? Math.ceil(max)
        : Math.ceil(max / 5) * 5
}

/////////// Utilities

function get_column_count(list) {
    return 2 * list.length - 2
}

function get_float(value) {
    return value || Number(Number(value).toFixed(1))
}
function get_integer(value) {
    return value || Math.round(value)
}

function format_number(value, type, formatter) {
    if (is_empty(value)) {
        return '"No data"'
    }

    var formatted

    const format = function(val){
      return(0 === val ? '0' : formatter(val))
    }

    switch (type) {
      case 'percentage':
        formatted = format(value)
        formatted = `${ formatted }%`
        break
      case 'rate':
        formatted = d3_format_integer(value)
        break
      default:
        formatted = format(value)
        break
    }

    return formatted
}
function format_float(value, type) {
    return format_number(value, type, d3_format_float)
}
function format_integer(value, type) {
    return format_number(value, type, d3_format_integer)
}

function find_item_index({ item, list }) {
    return list.findIndex(function _find_item_index(candidate) {
        return item.label === candidate.label
            && item.value === candidate.value
    })
}

function is_empty(value) {
    return undefined === value || null === value
}
