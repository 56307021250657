import React, { // babel expects "React" to be cased as a class, not a namespace
} from "react"
import PROP_TYPES from "prop-types"

import ordinal_indicator from "ordinal/indicator"
import { format as d3_format } from "d3"
const formatters = {
    rate: d3_format(",.0d"),
    count: d3_format(",.0d"),
    rank: d3_format(",.0d"),
    percentage: d3_format(",.1f"),
}

export default extend_component(Component)

/////////// React component

function Component(props) {
    const params = {
        props: props,
    }
    return render_component(params)
}

function render_component({ props }) {
    const { type, types, rows } = props
    const value_heading
        = types ? null
        : "rate" === type ? "Rate per 100,000"
        : "count" === type ? "Total count"
        : "percentage" === type ? "Population %"
        : "???"
    const rank_heading = "Ranking"
    const rendered_rows = rows.map(render_row)
    return <table className="stats-table">
        <thead>
            <tr>
                <th/>
                <th>{ value_heading }</th>
                <th>{ rank_heading }</th>
            </tr>
        </thead>
        <tbody>
            { rendered_rows }
        </tbody>
    </table>

    ///////////

    function render_row(row_data, i) {
        const heading = row_data[0]
        const value_type = types
            && types[i]
            || type
            || "count"
        const value = format(value_type, row_data[1])
        const rank = value_type === "subheading"
            ? null
            : format("rank", row_data[2])
        const rank_indicator = rank && !isNaN(rank)
            ? <sup>{ ordinal_indicator(rank) }</sup>
            : null
        return <tr key={ i }>
            <th>{ heading }</th>
            <td>{ value }</td>
            <td>{ rank }{ rank_indicator }</td>
        </tr>
    }
}

function extend_component(component) {
    const type_prop_type = PROP_TYPES.oneOf([
        "rate",
        "count",
        "percentage",
        "rank",
        "index",
    ])
    component.propTypes = {
        type: type_prop_type,
        types: PROP_TYPES.arrayOf(type_prop_type),
        rows: PROP_TYPES.arrayOf(
            PROP_TYPES.arrayOf(
                PROP_TYPES.oneOfType([
                    PROP_TYPES.string,
                    PROP_TYPES.number,
                ])
            )
        ).isRequired,
    }
    return component
}

/////////// Utilities

function format(type, value) {
    if (type === "subheading") {
        return null
    } else if (is_empty(value)) {
        return "No Data"
    }
    const number = get_number(type, value)
    if (!number) {
        return "0"
    }
    const formatter = formatters[type]
    const formatted_number = formatter(number)
    if (type === "percentage") {
        return `${ formatted_number }%`
    }
    return formatted_number
}

function get_number(type, value) {
    const number = Number(value)
    switch (true) {
        case number === 0:
            return number
        case type === "rate":
            return Number(number.toFixed(3))
        case type === "count":
            return Number(number.toFixed(1))
        case type === "percentage":
            return Number(number.toFixed(2))
        default:
            return number
    }
}

function is_empty(value) {
    return undefined === value || null === value
}
